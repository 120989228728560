import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueFilter from 'vue-filter'
import VueClipboard from 'vue-clipboard2'
import Vuelidate from 'vuelidate'
import vueNumeralFilterInstaller from 'vue-numeral-filter'

Vue.use(require('vue-moment'))
Vue.use(VueLodash, {lodash: lodash})
Vue.use(VueFilter)
Vue.use(VueClipboard)
Vue.use(Vuelidate)
Vue.use(vueNumeralFilterInstaller)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
    <div class="text-center text-primary-two mt-24 mb-12 mx-1 leading-none">
        <div style="padding-left: 20px;" class="simpleFooter">
            <a href="https://snack.calendarsnack.com" target="_blank">
                <img src="@/assets/img/calsnack-logo-wide-v2.png" width="300px">
            </a>
        </div>
        <div style="font-size: 1.25rem; padding-top: 10px; font-weight: 600;">
            if it's not on the calendar, it doesn't exist
        </div>
        <div style="font-size: .9rem; padding-bottom: 10px; font-weight: 400;">
            start sending calendar invitations and recieving RSVPs
        </div>
        <div class="footerText">
            Powered by 
                <a href="https://app.calendarsnack.com" target="_blank">
                    <span class="hover:text-indigo-900 hover:font-semibold underline">Calendar Snack</span>
                </a>, a service of 
                <a href="https://31events.com" target="_blank">
                    <span class="hover:text-indigo-900 hover:font-semibold underline">31Events</span>
                </a> &copy; 2020-2022 All Rights Reserved
        </div>
    </div>
</template>

<script>
export default {

  components: {

  },

  data () {
    return {

    }
  },

  created () {

  },

  methods: {

  }

}
</script>

<style scoped>
.footerIcon {
    float: left;
    margin-right: 10px;
}

.footerText {
    margin-top: 2px;
    font-size: .8rem;
}

.simpleFooter img {
    margin: 0 auto;
}

.poweredText {
    font-size: 10px;
    text-align: center;
}

</style>

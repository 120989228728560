import Vue from 'vue'
import Vuex from 'vuex'
import '@/plugins/axios'
import { EVENTAPI, EVENTKEY, ORGAPI } from '@/assets/js/api.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    orgEmail: '',
    oneEvent: [],
    eventInvites: [],
    eventStats: [],
    orgEvents: [],
    orgStats: []
  },

  mutations: {
    SET_EVENT (state, oneEvent) {
      state.oneEvent = oneEvent
    },

    SET_EVENT_INVITES (state, eventInvites) {
      state.eventInvites = eventInvites
    },

    SET_EVENT_STATS (state, eventStats) {
      state.eventStats = eventStats
    },

    SET_ORG (state, orgEvents) {
      state.orgEvents = orgEvents
    },

    SET_ORG_STATS (state, orgStats) {
      state.orgStats = orgStats
    }
  },

  actions: {
    LOAD_EVENT ({ commit }, eventid) {
      return axios
        .get(EVENTAPI + eventid, {
          headers: {
            'content-type': 'application/json',
            'x-api-key': EVENTKEY
          }
        })
        .then(response => {
          commit('SET_EVENT', response.data)
        })
    },

    LOAD_EVENT_INVITES ({ commit }, eventid) {
      return axios
        .get(EVENTAPI + eventid + '/attendees', {
          headers: {
            'content-type': 'application/json',
            'x-api-key': EVENTKEY
          }
        })
        .then(response => {
          commit('SET_EVENT_INVITES', response.data)
        })
    },

    LOAD_EVENT_STATS ({ commit }, eventid) {
      return axios
        .get(EVENTAPI + eventid + '/statistics', {
          headers: {
            'content-type': 'application/json',
            'x-api-key': EVENTKEY
          }
        })
        .then(response => {
          commit('SET_EVENT_STATS', response.data)
        })
    },

    LOAD_ORGEVENTS ({ commit }, orgEmail) {
      return axios
        .get(ORGAPI + orgEmail + '/events', {
          headers: {
            'content-type': 'application/json',
            'x-api-key': EVENTKEY
          }
        })
        .then(response => {
          commit('SET_ORG', response.data)
        })
    },

    LOAD_ORGEVENTS_STATS ({ commit }, orgEmail) {
      return axios
        .get(ORGAPI + orgEmail + '/statistics', {
          headers: {
            'content-type': 'application/json',
            'x-api-key': EVENTKEY
          }
        })
        .then(response => {
          commit('SET_ORG_STATS', response.data)
        })
    }
  }
})

// dev 2020-May API
/* export const SNACKAPI = 'https://blue-events.calendarsnack.com/'
export const SNACKKEY = 'kgjxXzkPMu6ZtAjXwiQzg2JxA5dk1JkB2RxQiZVk'

export const ADMINAPI = 'https://blue-admin.calendarsnack.com'
export const ADMINKEY = 'RAW4lS6eqb1pPJUw6XGsK5eCI6nLwxiT7YeLVChX'

export const POWERBARAPI = 'https://blue-powerbar.calendarsnack.com/shadow/'
export const POWERBARKEY = 'kgjxXzkPMu6ZtAjXwiQzg2JxA5dk1JkB2RxQiZVk'

export const ENROLLAPI = 'https://blue-powerbar.calendarsnack.com/organizer/enroll?subscription=' */

// 2021-April API
export const EVENTAPI = 'https://api.calendarsnack.com/event/'
export const EVENTKEY = 'xctb5kBJccJO4hitSrjbaOqxe1ra0I5huqpWamg0'

export const ORGAPI = 'https://api.calendarsnack.com/organizer/shadow/'
export const ORGKEY = 'xctb5kBJccJO4hitSrjbaOqxe1ra0I5huqpWamg0'

export const ADMINAPI = 'https://api.calendarsnack.com/system/events'
export const ADMINKEY = 'xctb5kBJccJO4hitSrjbaOqxe1ra0I5huqpWamg0'

export const ENROLLAPI = 'https://api.calendarsnack.com/organizer/enroll?subscription='

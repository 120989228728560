import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    component: Home
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/upcoming/:eventid',
    name: 'Upcoming',
    component: () => import('../views/Upcoming2.vue'),
    props: (route) => ({
      eventid: route.params.eventid
    })
  },
  {
    path: '/upcoming/2/:eventid',
    name: 'Upcoming2',
    component: () => import('../views/Upcoming2.vue'),
    props: (route) => ({
      eventid: route.params.eventid
    })
  },
  {
    path: '/event/:eventid',
    name: 'Upcoming',
    component: () => import('../views/Event.vue'),
    props: (route) => ({
      eventid: route.params.eventid
    })
  },
  {
    path: '/event/2/:eventid',
    name: 'Upcoming',
    component: () => import('../views/Event.vue'),
    props: (route) => ({
      eventid: route.params.eventid
    })
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
  <div id="app">
    <router-view/>
    <PageFooter />
  </div>
</template>

<script>
import PageFooter from '@/components/common/Footer.vue'
export default {
  components: {
    PageFooter
  }
}
</script>

<style>
body {
  background-color: rgba(243, 244, 246);
}

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.text-fadein {
opacity: 1;
animation: fadeIn ease 5s;
-webkit-animation: fadeIn ease 5s;
}

@keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-moz-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-o-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-ms-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}
</style>